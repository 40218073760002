import moment from 'moment/moment';
import { SelectOption } from '../components/inputs/CustomSelect';
import { SchedulerDetailsDataT } from '../pages/SettingsSections/SchedulerSection/ScheduleTab';
import { DetailsFormInputs } from '../pages/UnitPage/Sections/Details';
import { MeType, RolesType } from '../store/auth/types';
import { LocalStorageKeys } from '../store/keys';
import { UnitSchedule, UnitType } from '../store/unit/actions';
import {
  amexPattern,
  cup1Pattern,
  cup2Pattern,
  dinersPattern,
  disco1Pattern,
  disco2Pattern,
  disco3Pattern,
  jcbPattern,
  mastercard2Pattern,
  mastercardPattern,
  visaPattern,
} from './regexPatterns';
import { w3color } from './w3color';
import { DealerType } from '../store/dealers/actions';
import { SchedulerRoles } from '../store/constants';
import { queryClient } from '../App';

export const formSelectOptions = (arr: readonly string[] | string[] | number[]): SelectOption[] => arr.map((el) => ({ value: String(el), label: String(el) }));

export const arrayRange = (start: number, stop: number, step: number) => Array.from(
  { length: (stop - start) / step + 1 },
  (value, index) => stop - index * step,
);

export type InFtType = {
    feet: number
    inches: number
}

export const convertInToInFt = (inches: number): InFtType => {
  const feet = Math.floor(inches / 12);
  const leftover = inches % 12;
  return {
    feet,
    inches: leftover,
  };
};

export const convertInFtToTotalIn = (inches: number, feet: number): number => (feet * 12) + inches;

export const setLocalStorage = (key: LocalStorageKeys, value: string) => localStorage.setItem(key, value);
export const getLocalStorage = (key: LocalStorageKeys) => localStorage.getItem(key);
export const removeLocalStorage = (key: LocalStorageKeys) => localStorage.removeItem(key);
export const intercomInitialization = (user: MeType, selectedDealer?: DealerType) => {
  if (window.Intercom) {
    if (!user.role.includes('admin') || (selectedDealer && !user.role.includes('admin'))) {
      window.intercomSettings = {
        app_id: process.env.REACT_APP_INTERCOM_ID,
        email: selectedDealer?.primary_email || user?.primary_email,
        phone: selectedDealer?.phone_number || user?.dealer_phone_number,
        company: {
          id: selectedDealer?.id || user.dealer_id,
          name: selectedDealer?.dealership_name || String(user.dealership_name),
          location: selectedDealer?.main_location_name || String(user.main_location_name),
          phone: selectedDealer?.phone_number || user?.dealer_phone_number,
          email: selectedDealer?.primary_email || user?.primary_email,
          marketing_tools: roleHasAccess(selectedDealer?.role || user?.role, SchedulerRoles),
        },
      };
    }
    if (!user.role.includes('admin')) {
      window.Intercom('boot', {
        app_id: process.env.REACT_APP_INTERCOM_ID,
        name: selectedDealer?.dealership_name || user?.dealership_name || '',
        email: selectedDealer?.primary_email || user?.email || '',
        user_id: String(user.id) || '',
        company_id: selectedDealer?.id || user?.dealer_id || '',
        ...{
          ...(!user.role.includes('admin') || (selectedDealer && !user.role.includes('admin'))) && {
            company: {
              name: selectedDealer?.dealership_name || String(user.dealership_name),
              id: selectedDealer?.id || user.dealer_id,
              company_id: selectedDealer?.id || user.dealer_id,
              email: selectedDealer?.primary_email || user.primary_email,
              phone: selectedDealer?.phone_number || user?.dealer_phone_number,
              marketing_tools: roleHasAccess(selectedDealer?.role || user?.role, SchedulerRoles),
            },
          },
        },
      });
    }
  }
};

export const getToken = () => getLocalStorage(LocalStorageKeys.TOKEN);

export const clearLocalStorage = () => {
  removeLocalStorage(LocalStorageKeys.TOKEN);
  removeLocalStorage(LocalStorageKeys.DEALER_ID);
  queryClient.resetQueries();
  localStorage.removeItem('REACT_QUERY_OFFLINE_CACHE'); // clear persisted store
  if (window.Intercom) {
    window.Intercom('shutdown');
  }
};

export function toHex(n: number) {
  let hex = n.toString(16);
  while (hex.length < 2) { hex = `0${hex}`; }
  return hex;
}
export const toHexString = ({ red, green, blue }: { red: number, green: number, blue: number }) => {
  const r = toHex(red);
  const g = toHex(green);
  const b = toHex(blue);
  return `#${r}${g}${b}`;
};
export const stringToHex = (value: string) => toHexString(w3color(value));

export type DeepKeysArrAndObj<T, Key extends keyof T = keyof T> = Key extends string
    ? T[Key] extends string | number
        ? Key
        :
        T[Key] extends Array<string>
            ? `${Key}.${number}`
            :
            T[Key] extends Array<object>
                ? `${Key}.${number}.${DeepKeysArrAndObj<T[Key][number]>}`
                : `${Key}.${DeepKeysArrAndObj<T[Key]>}`
    : never;

const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const niceBytes = (x: number) => {
  let l = 0; let
    n = parseInt(`${x}`, 10) || 0;

  // eslint-disable-next-line no-plusplus
  while (n >= 1024 && ++l) {
    n /= 1024;
  }
  return (`${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`);
};

export type TimeOption = {
  value: string
  label: string
  time: string
  meridiem: 'AM' | 'PM'
  avaliable?: boolean
}
export const getTimeOptions = (): TimeOption[] => {
  const timeArray = [];
  const d = moment().startOf('day').toDate();
  const h = d.getHours();
  const m = d.getMinutes();
  const meridiem: TimeOption['meridiem'][] = ['AM', 'PM'];
  for (let i = h; i < 24; i += 1) {
    for (let j = i === h ? Math.ceil(m / 5) : 0; j < 12; j += 1) {
      // eslint-disable-next-line no-nested-ternary
      const time = `${String(i % 12) === '0' ? '12' : String(i % 12).length === 1 ? `0${i % 12}` : i % 12}:${(j * 5).toString().length !== 1 ? j * 5 : `0${j * 5}` || '00'}`;
      // eslint-disable-next-line no-bitwise
      const label = `${time} ${meridiem[i / 12 | 0]}`;
      timeArray.push({
        // eslint-disable-next-line no-bitwise
        label, value: label, time, meridiem: meridiem[i / 12 | 0],
      });
    }
  }
  return timeArray;
};

export const getAutoPostingTimeOptions = (): TimeOption[] => {
  const timeArray = [];
  const meridiem: TimeOption['meridiem'][] = ['AM', 'PM'];
  for (let i = 0; i < 24; i += 1) {
    for (let j = 0; j < 2; j += 1) {
      const hours = i % 12 || 12;
      const minutes = j * 30;
      const paddedHours = String(hours).padStart(2, '0');
      const paddedMinutes = String(minutes).padStart(2, '0');
      const time = `${paddedHours}:${paddedMinutes}`;
      // eslint-disable-next-line no-bitwise
      const label = `${time} ${meridiem[i / 12 | 0]}`;
      timeArray.push({
        label,
        value: label,
        time,
        // eslint-disable-next-line no-bitwise
        meridiem: meridiem[i / 12 | 0],
      });
    }
  }
  return timeArray;
};

export type CreditCardsType = 'visa' |
'amex' |
'mastercard' |
'discover' |
'diners' |
'jcb' |
'china_union_pay'

export function creditCardType(cardNumber: string): CreditCardsType | undefined {
  if (cardNumber.match(new RegExp(visaPattern))) {
    return 'visa';
  }
  if (cardNumber.match(new RegExp(amexPattern))) {
    return 'amex';
  }
  if (cardNumber.match(new RegExp(mastercardPattern)) || cardNumber.match(new RegExp(mastercard2Pattern))) {
    return 'mastercard';
  }
  if (cardNumber.match(new RegExp(disco1Pattern)) || cardNumber.match(new RegExp(disco2Pattern)) || cardNumber.match(new RegExp(disco3Pattern))) {
    return 'discover';
  }
  if (cardNumber.match(new RegExp(dinersPattern))) {
    return 'diners';
  }
  if (cardNumber.match(new RegExp(jcbPattern))) {
    return 'jcb';
  }
  if (cardNumber.match(new RegExp(cup1Pattern)) || cardNumber.match(new RegExp(cup2Pattern))) {
    return 'china_union_pay';
  }
  return undefined;
}

export type FixedLengthArr<
    T,
    N extends number,
    R extends readonly T[] = [],
    > = R['length'] extends N ? R : FixedLengthArr<T, N, readonly [T, ...R]>;

export const getLogoPosition = (logoPlacement: string) => {
  switch (logoPlacement) {
    case 'Lower Left':
      return 'll';
    case 'Lower Right':
      return 'lr';
    case 'Upper Left':
      return 'ul';
    case 'Upper Right':
      return 'ur';
    default:
      return 'disabled';
  }
};

export const removeMultipleSpaces = (value: string): string => value.replace(/ +(?= )/g, '');

export const formUnitPrice = (details : UnitType['details'] | DetailsFormInputs): number | string | undefined => {
  let priceToDisplay = details?.price;
  if (Number(details?.msrp) > 0) {
    priceToDisplay = details?.msrp;
  }
  if (Number(details?.msrp) > 0 && Number(details?.price) > 0) {
    priceToDisplay = details?.price;
  }
  if (Number(details?.msrp) > 0 && Number(details?.sales_price) > 0) {
    priceToDisplay = details?.sales_price;
  }
  if (Number(details?.msrp) > 0 && Number(details?.price) > 0 && Number(details?.sales_price) > 0) {
    priceToDisplay = details?.sales_price;
  }
  return priceToDisplay;
};

type ValidationType = {
  [key: string]: string
}

export const SchedulerAvailableStatuses = [
  'Available',
  'On Order',
  'Special Order',
];

export type UnitPostingReturnT = {
  unitValidation: {
    [key in string]: string
  }
  lackOfBudget?: boolean
  balanceNeeded?: number
}
export type FieldsToSchedulePostingValidateT = {
  year: string | undefined
  imagesCount: number | undefined
  dealership_location: UnitType['dealership_location'] | undefined | null
  manufacturer: UnitType['manufacturer'] | undefined
  model_name: UnitType['model_name'] | undefined
  details: DetailsFormInputs | UnitType['details'] | null
  description: string | null | undefined
  status: UnitType['status'] | undefined
  title: UnitType['title'] | undefined
  overall_length: string | undefined
  category: UnitType['item_category'] | undefined
}
type PostingValidationHelperT = {
  selectedItems: number[]
  fieldsToValidate: FieldsToSchedulePostingValidateT
  isUrgent?: SchedulerDetailsDataT['urgent']
  unitID: number
} & (
    {postingType: 'facebook', balance?: number } |
    {postingType: 'craigslist', balance: number}
    )

type CLPostingValidation = Pick<PostingValidationHelperT, 'fieldsToValidate'>
const CLPostingValidation = ({
  fieldsToValidate,
}: CLPostingValidation): ValidationType => {
  const unitValidation: ValidationType = {};
  if (!fieldsToValidate?.title || fieldsToValidate?.title?.length > 70) {
    Object.assign(unitValidation, { title: 'Title must be less than 70 character long ' });
  }
  if ((fieldsToValidate?.overall_length?.length || 0) > 950) {
    Object.assign(unitValidation, { title: 'Overall length must be less than 950 character long ' });
  }
  if (!fieldsToValidate?.manufacturer || fieldsToValidate?.manufacturer?.length > 32) {
    Object.assign(unitValidation, { title: 'Manufacturer must be less than 32 character long ' });
  }
  if (!fieldsToValidate?.model_name || fieldsToValidate?.model_name?.length > 50) {
    Object.assign(unitValidation, { title: 'Model must be less than 50 character long ' });
  }
  return unitValidation;
};

export const unitPostingValidation = ({
  balance, isUrgent, selectedItems, postingType, fieldsToValidate, unitID,
}: PostingValidationHelperT): UnitPostingReturnT => {
  if (!fieldsToValidate) {
    return { unitValidation: {} };
  }
  const unitValidation: ValidationType = {};
  const calculatedBalance = Number(balance) ? Number(balance) / 100 : '';
  const balanceNeeded = 6 * (selectedItems.length ? selectedItems.length + 1 : 1);
  const lackOfBudget = calculatedBalance < balanceNeeded;
  if (postingType === 'craigslist' && isUrgent && !selectedItems?.includes(unitID)) {
    if (selectedItems.length === 1) {
      Object.assign(unitValidation, { urgent: 'Post now" available only for 1 unit at the time' });
    } else {
      Object.assign(unitValidation, CLPostingValidation({
        fieldsToValidate,
      }));
    }
  }
  if (postingType === 'craigslist' && !isUrgent && !selectedItems?.includes(unitID)) {
    Object.assign(unitValidation, CLPostingValidation({
      fieldsToValidate,
    }));
    if (lackOfBudget) {
      Object.assign(unitValidation, { balance: `You should have have at least ${balanceNeeded} USD on balance to schedule post` });
    }
  }

  if (!fieldsToValidate.year || fieldsToValidate.year > `${moment().get('year') + 1}`) {
    Object.assign(unitValidation, { year: `Year should be <  ${moment().get('year') + 1}` });
  } if (!fieldsToValidate?.imagesCount || fieldsToValidate?.imagesCount < 1) {
    Object.assign(unitValidation, { images: 'Unit should contain images' });
  } if (!fieldsToValidate.dealership_location) {
    Object.assign(unitValidation, { location: 'Unit should contain location' });
  } if (!fieldsToValidate.manufacturer) {
    Object.assign(unitValidation, { manufacturer: 'Unit should contain manufacturer' });
  } if (!fieldsToValidate.model_name) {
    Object.assign(unitValidation, { model_name: 'Unit should contain model name' });
  } if (!fieldsToValidate?.details || Number(formUnitPrice(fieldsToValidate?.details)) <= 0) {
    Object.assign(unitValidation, { price: 'Price should be > 0' });
  } if (!fieldsToValidate.description) {
    Object.assign(unitValidation, { description: 'Unit should contain description' });
  }
  if (!fieldsToValidate.status || !SchedulerAvailableStatuses.includes(fieldsToValidate.status)) {
    Object.assign(unitValidation, { status: 'Unit should available to posting  status' });
  }
  return { unitValidation, lackOfBudget, balanceNeeded };
};

export const roleHasAccess = (userRoles: RolesType | undefined, allowedRoles: RolesType): boolean => (userRoles || [])?.some((role) => allowedRoles.includes(role));

export const isEditDisabled = (unitSchedules?: UnitSchedule[]) => {
  const schedules = unitSchedules?.filter((schedule) => (moment(schedule.schedule_date_time).diff(moment()) > 0)
      && schedule.status === 'Successful'
  && schedule?.type === 'craigslist');
  return !!schedules?.length;
};

export const formDate = (timestamp: string) : {date: string, time: string} => {
  const updatedAt = new Date(timestamp);
  const time = updatedAt.toLocaleDateString('en-US', {
    hour: 'numeric', minute: 'numeric', hour12: true,
  }).split(',')[1];
  const date = updatedAt.toLocaleDateString('en-US', {
    day: 'numeric', month: 'numeric', year: '2-digit',
  });
  return {
    date,
    time,
  };
};
