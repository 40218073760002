import { AxiosResponse, AxiosError } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { SortType } from 'rsuite-table';
import { queryClient } from '../../App';
import { AppMessageType } from '../../appMessagesContext';
import { UploadedFile } from '../../components/FileUploader';
import { DeepKeysArrAndObj } from '../../helpers';
import { useSoldUnit } from '../../hooks/useSoldUnit';
import { CalendarEventStatus } from '../../pages/SettingsSections/SchedulerSection/PendingTab';
import { CustomFeatureT } from '../../pages/UnitPage/Sections/Features';
import { FeatureTitle } from '../../pages/UnitPage/Sections/Features/featureList';
import { RequestKeys } from '../keys';
import { CreateCLScheduleRequestData } from '../settings/actions';
import { PendingUnitRequest, ResponseWithPagination, TempUnitFormData } from '../types';
import { UnitRequests } from './requests';

export type Inventories = 'Building' | 'Equipment' | 'Horse Trailer' | 'Recreational Vehicle' | 'Semi-Trailer' | 'Semi-Truck' | 'Sports Vehicle' | 'Trailer or Truck Bed' | 'Watercraft' | 'Vehicle'
export type ItemType = {
    id: number
    name: Inventories
}
export const getItemTypesRequest = () => {
  const response = useQuery<AxiosResponse<ItemType[]>>(
    {
      queryKey: [RequestKeys.GET_UNIT_TYPES],
      queryFn: UnitRequests.GetItemTypes,
    },
  );
  return { responseData: response?.data?.data || [], ...response };
};

export type ItemCategory = {
    id: number
    item_type_id: number
    name: string
}
export type ItemCategoryResponse = {
    id: number
    name: Inventories
    item_categories: ItemCategory[]
}
export const getItemCategoriesRequest = () => {
  const response = useQuery<AxiosResponse<ItemCategoryResponse[]>>(
    {
      queryKey: [RequestKeys.GET_UNIT_CATEGORIES],
      queryFn: UnitRequests.GetItemCategories,
    },
  );
  return { responseData: response?.data?.data || [], ...response };
};

export type ItemAttribute = {
    id: number
    name: string
    alias: string
    options: null | string[]
}
export type ItemAttributesResponse = {
    id: number
    name: Inventories
    attributes: ItemAttribute[]
}
export const getItemAttributesRequest = () => {
  const response = useQuery<AxiosResponse<ItemAttributesResponse[]>>(
    {
      queryKey: [RequestKeys.GET_UNIT_ATTRIBUTES],
      queryFn: UnitRequests.GetItemAttributesRequest,
    },
  );
  return { responseData: response?.data?.data || [], ...response };
};

export type ItemFeature = {
    id: number
    item_feature_id: number
    name: string
    is_active?: boolean
    is_custom: boolean
    is_new?: boolean
}
export type ItemFeaturesResponse = {
    id: number
    name: FeatureTitle
    feature_options: ItemFeature[]
}
export const getItemFeaturesRequest = () => {
  const response = useQuery<AxiosResponse<ItemFeaturesResponse[]>>(
    {
      queryKey: [RequestKeys.GET_UNIT_FEATURES],
      queryFn: UnitRequests.GetItemFeaturesRequest,
    },
  );
  return { responseData: response?.data?.data || [], ...response };
};

export type CustomFeatureFormData = Omit<CustomFeatureT, 'is_active'> & {
    is_active: 1 | 0
}
export type CreateUnitRequestData = {
    specifics: {
            title: string,
            item_type_id: number,
            stock: string,
            condition: string,
            autopopulate: string,
            year: string,
            manufacturer: string,
            status: string,
            dealership_location_id: number,
            vin: string,
            model_name: string,
            item_category_id: number
            chassis_year?: string
            brand?: string
            overlay_setting?: string
        },
    details: {
            price: number | string
            hidden_price: number | string
            msrp: number | string
            monthly_payment: number | string
            sales_price: number | string
            website_price: number | string
            notes: string,
            description: number | string,
            floor_height: number | string,
            floor_length: number | string,
            floor_width: number | string,
            gvwr: number | string,
            weight: number | string,
            payload_capacity: number | string,
            axle_capacity: number | string,
        },
    admin_details: {
        unit_cost?: string
        prep_fee?: string
        freight_cost?: string
        packaging_fee?: string
        total_cost?: string
        true_total_cost?: string
    },
    attributes: {
        overall_length: string
        min_width: string
        min_height: string
          [key: string]: string
        },
    features: number[],
    custom_features: CustomFeatureFormData[],
    features_to_delete: number[],
    website_config: {
            is_active?: 1 | 0,
            racing_junk?: 1 | 0,
            is_featured?: 1 | 0,
            is_on_special?: 1 | 0,
            custom_status: string
            video_embed_code: string[]
        }
    [key: `images[${number}]`]: UploadedFile | number | string
    [key: `pdfs[${number}]`]: UploadedFile | number | string
    [key: `hidden_files[${number}]`]: UploadedFile | number | string
    dealer_id?: number
}
export type CloneUnitRequestData = {
    stock: string
}
export type CloneUnitReqError = {
    stock: string
}

export type UnitFeature = {
    id: number,
    name: string
    is_custom: boolean
    is_active?: boolean
}

export type FeatureKeys = FeatureTitle

export type FileFromResponse = {
    id: number
    name: string
    order: number
    size: number
    type: string
    url: string
    thumb_uri?: string | null
    isFile?: boolean
}

export type UnitSchedule = {
    created_at: string
    dealer_id: number
    id: number
    item_id: number
    posting_id: null | string
    posting_url: null | string
    profile_id: number
    schedule_date_time: string
    status: CalendarEventStatus
    time_zone: string
    type: CreateCLScheduleRequestData['type']
    updated_at: string
    is_urgent?: boolean
}

export type UnitUserOverlaySetting = {
    logo_placement: string
    logo_size: number
    logo_url: string
    lower_background_color: string
    lower_text: string
    lower_text_color: string
    upper_background_color: string
    upper_text: string
    upper_text_color: string
}
export type UnitType = {
    id: number,
    dealer: {
        id: number,
        code: number,
        dealership_name: string
    },
    dealership_location: {
        id: number,
        is_main: boolean,
        name: string
    },
    title: string,
    item_type: ItemType,
    item_category: {
        id: number,
        name: string
    },
    stock: string,
    condition: string,
    autopopulate: null | string,
    year: string,
    manufacturer: string,
    status: string,
    vin: string | null
    model_name: string,
    is_archived: null | boolean,
    created_at: string,
    updated_at: string,
    chassis_year?: string
    brand?: string
    details: {
        price: number | string,
        hidden_price: number | string,
        msrp: number | string,
        monthly_payment: number | string,
        sales_price: number | string,
        total_cost: null | number | string,
        website_price: number | string,
        different_website_price: null | number | string,
        d2d_price: null | number | string,
        notes: string | null
        description: string | null
        floor_height: number | string,
        floor_length: number | string,
        floor_width: number | string,
        gvwr: number | string,
        weight: number | string,
        payload_capacity: number | string,
        axle_capacity: number | string
    } | null,
    admin_details: {
        freight_cost: string | null
        packaging_fee: string | null
        prep_fee: string | null
        total_cost: string | null
        unit_cost: string | null
        true_total_cost: string | null
    } | null,
    attributes: {
        min_height: string,
        min_width: string,
        min_length: string,
        side_wall_height: string,
        overall_length: string
        [key: string]: string
    },
    features: {[key in FeatureKeys]: UnitFeature[]},
    website_configuration: {
        is_active: boolean,
        racing_junk?: boolean,
        is_featured: boolean,
        is_on_special: boolean,
        custom_status: string | null,
        video_embed_code: string[] | null
    } | null,
    overlay_setting: string
    user_overlay_settings?: UnitUserOverlaySetting
    images: FileFromResponse[], // file
    images_count: number,
    pdfs: FileFromResponse[], // file
    hidden_files: FileFromResponse[] // file
    dealer_schedules: UnitSchedule []
}

export type CreateUnitReqError = {
    [key in DeepKeysArrAndObj<Omit<CreateUnitRequestData, 'images' | 'pdfs' | 'hidden_files'>>]: string
} & {
    'specifics.dealership_location': string
    'specifics.item_category': string
}
export const CreateUnitRequest = () => {
  const mutation = useMutation <AxiosResponse<UnitType>, AxiosError<{ errors: CreateUnitReqError, message: string }>,
      { data: CreateUnitRequestData, showMessage:(val: AppMessageType) => void, newUnitID: string, dealer_id?: number }>(
        {
          mutationKey: [RequestKeys.CREATE_UNIT],
          mutationFn: ({ data }) => UnitRequests.CreateUnit(data),
          onSuccess: (data, variables) => {
            variables.showMessage({ type: 'success', message: 'Unit Created successfully' });
            queryClient.setQueryData<TempUnitFormData>(
              [RequestKeys.TEMPORARY_UNIT_FORM_DATA, variables.newUnitID],
              () => ({ data: null }),
            );
            queryClient.setQueryData<PendingUnitRequest[]>(
              [RequestKeys.PENDING_REQUESTS],
              (oldData) => {
                // eslint-disable-next-line eqeqeq
                const indexOfPrevData = (oldData || []).findIndex((el: PendingUnitRequest) => el?.unitID === variables?.newUnitID);
                const pendingData: PendingUnitRequest = {
                  isLocalNotification: true,
                  isNewUnit: true,
                  status: 'SUCCESS',
                  unitID: data?.data?.id,
                  readed: false,
                  updated_at: new Date().toISOString(),
                };
                const tempArr = [...(oldData || [])];
                if (Array.isArray(oldData) && indexOfPrevData >= 0) {
                  // eslint-disable-next-line no-param-reassign
                  tempArr[indexOfPrevData] = pendingData;
                } else {
                  tempArr.push(pendingData);
                }
                return tempArr;
              },
            );
            const refetchQuery = queryClient.getQueryData<() => void>([RequestKeys.REFETCH_INVENTORY]);
            refetchQuery?.();
          },
          onError: (error, variables) => {
            queryClient.setQueryData<PendingUnitRequest[]>(
              [RequestKeys.PENDING_REQUESTS],
              (oldData) => {
                const indexOfPrevData = (oldData || [])?.findIndex((el: PendingUnitRequest) => el?.unitID === variables.newUnitID);
                const pendingData: PendingUnitRequest = {
                  isLocalNotification: true,
                  isNewUnit: true,
                  status: 'ERROR',
                  errors: error?.response?.data?.errors,
                  unitID: variables?.newUnitID,
                  readed: false,
                  updated_at: new Date().toISOString(),
                };
                const tempArr = [...(oldData || [])];
                if (Array.isArray(oldData) && indexOfPrevData >= 0) {
                  // eslint-disable-next-line no-param-reassign
                  tempArr[indexOfPrevData] = pendingData;
                } else {
                  tempArr.push(pendingData);
                }
                return tempArr;
              },
            );
          },
        });
  return { ...mutation };
};

export const CloneUnitRequest = () => {
  const mutation = useMutation <AxiosResponse<UnitType>, AxiosError<{ errors: CloneUnitReqError, message: string }>, {id: string | number, data: CloneUnitRequestData}>(
    {
      mutationKey: [RequestKeys.CLONE_UNIT],
      mutationFn: ({ id, data }) => UnitRequests.CloneUnit(id, data),
    },
  );
  return { ...mutation };
};

export const UpdateUnitRequest = () => {
  const useSold = useSoldUnit();
  const mutation = useMutation <AxiosResponse<UnitType>, AxiosError<{ errors: CreateUnitReqError, message: string }>,
      {data: CreateUnitRequestData, id: string | number,
          initialStatus: string, showMessage:(val: AppMessageType) => void
          dealer_id?: number
            }>(
            {
              mutationKey: [RequestKeys.UPDATE_UNIT],
              mutationFn: ({ data, id }) => UnitRequests.UpdateUnit(data, id),
              onSuccess: (data, variables) => {
                if (data?.data.status === 'Sold' && variables?.initialStatus !== 'Sold') {
                  useSold(data?.data);
                }
                queryClient.setQueryData<TempUnitFormData>(
                  [RequestKeys.TEMPORARY_UNIT_FORM_DATA, data?.data?.id.toString()],
                  () => ({ data: null }),
                );
                variables.showMessage({ type: 'success', message: 'Unit Updated successfully' });
                queryClient.setQueryData<PendingUnitRequest[]>(
                  [RequestKeys.PENDING_REQUESTS],
                  (oldData) => {
                    // eslint-disable-next-line eqeqeq
                    const indexOfPrevData = (oldData || []).findIndex((el: PendingUnitRequest) => el?.unitID == data?.data?.id);
                    const pendingData: PendingUnitRequest = {
                      isLocalNotification: true,
                      updated_at: new Date().toISOString(),
                      isNewUnit: false,
                      status: 'SUCCESS',
                      unitID: data?.data?.id,
                      readed: false,
                    };
                    const tempArr = [...(oldData || [])];
                    if (Array.isArray(oldData) && indexOfPrevData >= 0) {
                      // eslint-disable-next-line no-param-reassign
                      tempArr[indexOfPrevData] = pendingData;
                    } else {
                      tempArr.push(pendingData);
                    }
                    return tempArr;
                  },
                );
                const refetchQuery = queryClient.getQueryData<() => void>([RequestKeys.REFETCH_INVENTORY]);
                refetchQuery?.();
              },
              onError: (error, variables) => {
                queryClient.setQueryData<PendingUnitRequest[]>(
                  [RequestKeys.PENDING_REQUESTS],
                  (oldData) => {
                    // eslint-disable-next-line eqeqeq
                    const indexOfPrevData = (oldData || [])?.findIndex((el: PendingUnitRequest) => el?.unitID == variables?.id);
                    const pendingData: PendingUnitRequest = {
                      isLocalNotification: true,
                      isNewUnit: false,
                      status: 'ERROR',
                      unitID: variables?.id,
                      errors: error?.response?.data?.errors,
                      readed: false,
                      updated_at: new Date().toISOString(),
                    };
                    const tempArr = [...(oldData || [])];
                    if (Array.isArray(oldData) && indexOfPrevData >= 0) {
                      // eslint-disable-next-line no-param-reassign
                      tempArr[indexOfPrevData] = pendingData;
                    } else {
                      tempArr.push(pendingData);
                    }
                    return tempArr;
                  },
                );
              },
            });
  return { ...mutation };
};

export type ItemsPaginationParams ={
    limit?: number
    page?: number
    archived?: 1 | 0
    condition?: string[]
    location_id?: number[]
    manufacturer?: string[]
    order?: SortType
    sort?: string
    search?: string
    dealer_id? : number[]
    is_inventory_manager? : '0' | '1'
    status?: string[]
}
export const getUnits = (params: ItemsPaginationParams, disableRequest?: boolean) => {
  const response = useQuery<AxiosResponse<ResponseWithPagination<UnitType[]>>>(
    {
      queryKey: [RequestKeys.GET_ITEMS, params],
      queryFn: () => UnitRequests.GetItems(params),
      enabled: !disableRequest,
      staleTime: 2000,
      refetchOnReconnect: true,
    },
  );
  return { responseData: response?.data?.data?.data || [], ...response, meta: response?.data?.data?.meta };
};

export type UnitPrintType = Pick<UnitType, 'id' | 'stock' | 'title' | 'year' | 'manufacturer' | 'item_category' | 'model_name' |
'status' | 'details' | 'images_count' | 'overlay_setting' | 'user_overlay_settings' | 'dealership_location' | 'created_at' | 'updated_at'
> & {
    primary_image: FileFromResponse
}
export const getUnitsForPrint = (params: ItemsPaginationParams, disableRequest?: boolean) => {
  const response = useQuery<AxiosResponse<ResponseWithPagination<UnitPrintType[]>>>(
    {
      queryKey: [RequestKeys.GET_ITEMS_FOR_PRINT, params],
      queryFn: () => UnitRequests.GetItemsForPrint(params),
      enabled: !disableRequest,
      staleTime: 2000,
      refetchOnReconnect: true,
    },
  );
  return { responseData: response?.data?.data?.data || [], ...response, meta: response?.data?.data?.meta };
};

export const getUnit = (id: string, disabled?: boolean) => {
  const response = useQuery<AxiosResponse<UnitType>, AxiosError>(
    {
      queryKey: [RequestKeys.GET_ITEM_BY_ID, id],
      queryFn: () => UnitRequests.GetItem(id),
      enabled: !disabled,
      refetchOnMount: true,
      staleTime: 1000,
    },
  );
  return { responseData: response?.data?.data, ...response };
};

export type LocationFilters = {
    id: number,
    is_main: boolean,
    name: string
    value?: string
    label?: string
}
export type CategoryFilters = {
    id: number,
    name: string,
    label: string
}

export type InventoryManagerFiltersType = {
    location: LocationFilters[],
    category: CategoryFilters[],
    condition: string[],
    length: number[],
    gvwr: string[],
    payload_capacity: number[],
    manufacturer: string[ ],
    manufacturer_options: string[ ],
    status: string[ ],
    price: number[],
    year: string[],
    color: string[],
    sort_options: string[],
    brand: string[],
    model_name: string[]
}

export const getInventoryManagerFilters = (params: {dealer_id?: number}, disableRequest?: boolean) => {
  const response = useQuery<AxiosResponse<InventoryManagerFiltersType>>(
    {
      queryKey: [RequestKeys.GET_INVENTORY_MANAGER_FILTERS, params],
      queryFn: () => UnitRequests.GetInventoryManagerFilters(params),
      enabled: !disableRequest,
      refetchOnMount: true,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data, ...response };
};

export const deleteBulkUnitsRequest = () => {
  const mutation = useMutation <AxiosResponse, AxiosError, {ids: number[], approveDelete?: boolean}>(
    {
      mutationKey: [RequestKeys.DELETE_BULK_UNITS],
      mutationFn: ({ ids, approveDelete }) => UnitRequests.DeleteUnits(ids, approveDelete),
    },
  );
  return { ...mutation };
};

export const deleteUnitRequest = () => {
  const mutation = useMutation <AxiosResponse, AxiosError, {id: number, params?: {approve_delete: '1' | '0'}}>(
    {
      mutationKey: [RequestKeys.DELETE_UNIT],
      mutationFn: ({ id, params }) => UnitRequests.DeleteUnit(id, params),
    },
  );
  return { ...mutation };
};
export const setActiveUnitsRequest = () => {
  const mutation = useMutation <AxiosResponse, AxiosError, {ids: number[]}>(
    {
      mutationKey: [RequestKeys.SET_ACTIVE_UNIT],
      mutationFn: ({ ids }) => UnitRequests.SetActiveUnits(ids),
    },
  );
  return { ...mutation };
};

export type CustomStatusesResponse = {
    custom_statuses: string[] | null
}
export const getCustomStatusesRequest = (params: {dealer_id?: string | number}) => {
  const response = useQuery<AxiosResponse<CustomStatusesResponse>>(
    {
      queryKey: [RequestKeys.GET_CUSTOM_STATUSES, params.dealer_id],
      queryFn: () => UnitRequests.GetCustomStatuses(params),
      enabled: !!params.dealer_id,
      refetchOnMount: true,
    },
  );
  return { responseData: response?.data?.data, ...response };
};

export type AutopopulateListParams = {
    year?: string | number
    model?: string
    unitManufacturer?: string
}

export type DatalinkListUnit = {
    id: number
    year: string
    model: string
    title: string
    unitManufacturer: string
}
export const getAutopopulateList = (params: AutopopulateListParams) => {
  const response = useQuery<AxiosResponse<{data: DatalinkListUnit[]}>>(
    {
      queryKey: [RequestKeys.GET_AUTOPOPULATE_LIST, params],
      queryFn: () => UnitRequests.GetAutopopulateList(params),
      enabled: !!Object.keys(params).length,
      refetchOnMount: true,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data?.data || [], ...response };
};
export type DatalinkUnit = {
    id: number
    title: string
    year: string
    model: string
    gvwr: string
    fenders: string // ??
    wheels: string // ??
    coupler: string // ??
    jack: string // ??
    electrical: string // feature?
    hitch: string // ?? Pull Type?
    axles: string // axles
    suspension: string // DL - string, TF - select
    ramp: string
    colors: string // DL - string, TF - select
    tongue: string // ??
    notes: string
    lengthTotal: string
    widthTotal: string
    rearDoors: string | null // feature?
    sideDoors: string | null // feature?
    createdAt: string
    updatedAt: string
    unitCategory: string
    unitManufacturer: string
    images: string[]
}
export const getAutopopulateitem = (id?: string | number) => {
  const response = useQuery<AxiosResponse<{data: DatalinkUnit}>>(
    {
      queryKey: [RequestKeys.GET_AUTOPOPULATE_ITEM, id],
      queryFn: () => UnitRequests.GetAutopopulateItem(id as string),
      enabled: !!id,
      refetchOnMount: true,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data?.data, ...response };
};

export type CreateManufacturerData = {
    name: string
}
export type CreateManufacturerResponse = {
    id: number
    name: string
}

export const CreateManufacturerRequest = () => {
  const mutation = useMutation <AxiosResponse<CreateManufacturerResponse>, AxiosError<{ errors: string, message: string }>, CreateManufacturerData>(
    {
      mutationKey: [RequestKeys.CREATE_MANUFACTURER],
      mutationFn: (data: CreateManufacturerData) => UnitRequests.CreateManufacturer(data),

    },
  );
  return { ...mutation };
};

export type ManufacturerRespT = {
    data: {
        id: number, name: string
    }[]
}
export const getManufacturesList = () => {
  const response = useQuery<AxiosResponse<ManufacturerRespT>>(
    {
      queryKey: [RequestKeys.GET_MANUFACTURES_LIST],
      queryFn: UnitRequests.GetManufacturesList,
      refetchOnMount: true,
      staleTime: 2000,
    },
  );
  return { responseData: response?.data?.data?.data, ...response };
};

export const SoldUnitRequest = () => {
  const mutation = useMutation <AxiosResponse, AxiosError<{ errors: string, message: string }>, number[]>(
    {
      mutationKey: [RequestKeys.SOLD_UNIT],
      mutationFn: (data: number[]) => UnitRequests.SoldUnit(data),
    },
  );
  return { ...mutation };
};

export type StockValidationT = {stock: string, dealer_id?: number, item_id?: string | number}
export type StockValidationErrors = {message: string, errors: {
        [key in keyof StockValidationT]
        : string
    }}
export const stockValidation = () => {
  const response = useMutation<AxiosResponse, AxiosError<StockValidationErrors>, StockValidationT>(
    {
      mutationKey: [RequestKeys.STOCK_VALIDATION],
      mutationFn: (data: StockValidationT) => UnitRequests.StockValidation(data),
    },
  );
  return { responseData: response?.data?.data?.data, ...response };
};
