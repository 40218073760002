import React, { useEffect } from 'react';
import styled from 'styled-components';
import CustomInput from '../../../../components/inputs/CustomInput';
import CustomSelect, { SelectValue } from '../../../../components/inputs/CustomSelect';
import TextArea from '../../../../components/inputs/TextArea';
import { convertInToInFt, roleHasAccess } from '../../../../helpers';
import { getMeRequest } from '../../../../store/auth/actions';
import { AdminCostRoles } from '../../../../store/constants';
import {
  CreateUnitReqError,
} from '../../../../store/unit/actions';
import { UnitFormInterface } from '../../index';
import { AttributesInputs } from '../Attributes';
import { isRentalOptions } from '../Attributes/data';

export interface DetailsFormInputs {
  notes: string

  price: string
  websitePrice: string
  sales_price: string
  msrp: string
  hiddenPrice: string
  pymt: string

  florHeightTotal: string
  florHeightIn: string
  florHeightFt: string

  florLengthTotal: string
  florLengthIn: string
  florLengthFt: string

  florWidthTotal: string
  florWidthIn: string
  florWidthFt: string

  weight: string
  payloadCapacity: string
  gvwr: string
  axleCapacity: string

  unit_cost?: string
  prep_fee?: string
  freight_cost?: string
  packaging_fee?: string
  total_cost?: string
  true_total_cost?: string
}

export type DetailsFormInputKey = keyof DetailsFormInputs

interface DetailsProps {
  initialValues: DetailsFormInputs
  onInputChange: (key: DetailsFormInputKey, value: string | SelectValue | number) => void
  requestErrors?: CreateUnitReqError
  attributesValues: AttributesInputs
  onAttributeInputChange: (key: string, value: string | SelectValue | number) => void
  errors?: {[key in keyof DetailsFormInputs]?: string}
  touched?: {[key in keyof UnitFormInterface['details'] ]?: string | boolean}
}

function Details({
  initialValues, onInputChange, requestErrors, attributesValues, onAttributeInputChange, errors, touched,
}: DetailsProps) {
  const user = getMeRequest()?.data?.data;

  useEffect(() => {
    const convertedIn = convertInToInFt(+initialValues.florHeightTotal);
    onInputChange('florHeightIn', convertedIn.inches || '');
    onInputChange('florHeightFt', convertedIn.feet || '');
  }, [initialValues.florHeightTotal]);

  useEffect(() => {
    const convertedIn = convertInToInFt(+initialValues.florLengthTotal);
    onInputChange('florLengthIn', convertedIn.inches || '');
    onInputChange('florLengthFt', convertedIn.feet || '');
  }, [initialValues.florLengthTotal]);

  useEffect(() => {
    const convertedIn = convertInToInFt(+initialValues.florWidthTotal);
    onInputChange('florWidthIn', convertedIn.inches || '');
    onInputChange('florWidthFt', convertedIn.feet || '');
  }, [initialValues.florWidthTotal]);

  const isActiveRental = (attributesValues.is_rental as SelectValue)?.value === 'Yes';

  const handleChangeAttribute = (value: SelectValue | string, controlInput: string) => {
    onAttributeInputChange(controlInput, value);
  };

  const displayAdminCost = roleHasAccess(user?.role, AdminCostRoles);

  const setTotalValue = (totalTrueCost: number) => {
    onInputChange('true_total_cost', Number(totalTrueCost).toFixed(2));
  };

  return (
    <DetailsSectionContainer>
      <div className="details-section-content">
        <div className="price-inputs">
          <div className="small-inputs-container">
            <CustomInput
              label="Price"
              wrapperClass="input-wrapper price"
              value={initialValues.price}
              onChange={(value) => onInputChange('price', value)}
              inputSymbol="$"
              pattern="decinumeric"
              error={(touched?.price && errors?.price) || requestErrors?.['details.price']?.[0]}
              min={0}
              max={999999}
            />
            <CustomInput
              label="Hidden Price"
              wrapperClass="input-wrapper price"
              inputSymbol="$"
              value={initialValues.hiddenPrice}
              onChange={(value) => onInputChange('hiddenPrice', value)}
              pattern="decinumeric"
              min={0}
              max={999999}
              error={requestErrors?.['details.hidden_price']?.[0]}
            />
          </div>
          <div className="small-inputs-container">
            <CustomInput
              label="Sales Price"
              wrapperClass="input-wrapper price"
              inputSymbol="$"
              value={initialValues.sales_price}
              onChange={(value) => onInputChange('sales_price', value)}
              pattern="decinumeric"
              min={0}
              max={999999}
              error={requestErrors?.['details.sales_price']?.[0]}
            />
            <CustomInput
              label="Website Price"
              wrapperClass="input-wrapper price"
              inputSymbol="$"
              value={initialValues.websitePrice}
              onChange={(value) => onInputChange('websitePrice', value)}
              pattern="decinumeric"
              min={0}
              max={999999}
              error={requestErrors?.['details.website_price']?.[0]}
            />
          </div>
          <div className="small-inputs-container">
            <CustomSelect
              label="Is Rental"
              className="select-wrapper price"
              required={false}
              options={isRentalOptions}
              value={attributesValues.is_rental as SelectValue}
              onChange={(value: SelectValue) => handleChangeAttribute(value, 'is_rental')}
              placeholder={' '}
              isClearable
              error={requestErrors?.[('attributes.is_rental')]?.[0]}
              indicatorContainerStyles={{
                marginTop: '0',
                height: 'inherit',
                svg: {
                  marginTop: '0',
                },
              }}
            />
            {isActiveRental
            && (
            <CustomInput
              label="Daily Rate"
              wrapperClass="input-wrapper price"
              pattern="decinumeric"
              min={0}
              max={999999}
              value={attributesValues?.daily_rate as string || ''}
              onChange={(value) => handleChangeAttribute(value, 'daily_rate')}
            />
            )}
          </div>
        </div>
        <div className="other-inputs">
          <div className="notes-input">
            <TextArea
              label="Notes"
              rows={3}
              value={initialValues.notes}
              onChange={(e) => onInputChange('notes', e.target.value)}
            />
          </div>
          <div className="price-inputs right">
            <div className="small-inputs-container">
              <CustomInput
                label="MSRP"
                wrapperClass="input-wrapper price"
                inputSymbol="$"
                value={initialValues.msrp}
                onChange={(value) => onInputChange('msrp', value)}
                pattern="decinumeric"
                min={0}
                max={999999}
                error={requestErrors?.['details.msrp']?.[0]}
              />
              <CustomInput
                label="Monthly Payment"
                wrapperClass="input-wrapper price"
                inputSymbol="$"
                value={initialValues.pymt}
                onChange={(value) => onInputChange('pymt', value)}
                pattern="decinumeric"
                min={0}
                max={999999}
                error={requestErrors?.['details.monthly_payment']?.[0]}
              />
            </div>
            {isActiveRental
                && (
                <div className="small-inputs-container">
                  <CustomInput
                    label="Weekly Rate"
                    wrapperClass="input-wrapper price"
                    pattern="decinumeric"
                    value={attributesValues?.weekly_rate as string || ''}
                    onChange={(value) => handleChangeAttribute(value, 'weekly_rate')}
                    min={0}
                    max={999999}
                  />
                  <CustomInput
                    label="Monthly Rate"
                    wrapperClass="input-wrapper price"
                    pattern="decinumeric"
                    value={attributesValues?.monthly_rate as string || ''}
                    onChange={(value) => handleChangeAttribute(value, 'monthly_rate')}
                    min={0}
                    max={999999}
                  />
                </div>
                )}
          </div>
        </div>
        {displayAdminCost
        && (
        <div className="price-inputs admin-cost">
          <div className="section-title details">
            <div>Admin Costs</div>
          </div>
          <div className="admin-inputs-container">
            <div className="small-inputs-container">
              <CustomInput
                label="Unit Cost"
                wrapperClass="input-wrapper price"
                value={initialValues.unit_cost}
                onChange={(value) => onInputChange('unit_cost', value)}
                onBlur={() => onInputChange('unit_cost', initialValues.unit_cost ? Number(initialValues.unit_cost).toFixed(2) : '')}
                inputSymbol="$"
                pattern="decinumeric"
                min={0}
                max={999999}
              />
              <CustomInput
                label="Prep Fee"
                wrapperClass="input-wrapper price"
                inputSymbol="$"
                value={initialValues.prep_fee}
                onChange={(value) => {
                  onInputChange('prep_fee', value);
                  setTotalValue((
                    Number(initialValues?.total_cost || 0)
                      + Number(initialValues?.freight_cost || 0)
                      + Number(value || 0)
                      + Number(initialValues?.packaging_fee || 0)
                  ));
                }}
                onBlur={() => onInputChange('prep_fee', initialValues.prep_fee ? Number(initialValues.prep_fee).toFixed(2) : '')}
                pattern="decinumeric"
                min={0}
                max={999999}
              />
              <CustomInput
                label="True Cost"
                wrapperClass="input-wrapper price"
                inputSymbol="$"
                value={initialValues.total_cost || ''}
                onChange={(value) => {
                  onInputChange('total_cost', value);
                  setTotalValue((
                    Number(value || 0)
                        + Number(initialValues?.freight_cost || 0)
                        + Number(initialValues?.prep_fee || 0)
                        + Number(initialValues?.packaging_fee || 0)
                  ));
                }}
                onBlur={() => onInputChange('total_cost', initialValues.total_cost ? Number(initialValues.total_cost).toFixed(2) : '')}
                pattern="decinumeric"
                min={0}
              />
            </div>
            <div className="small-inputs-container">
              <CustomInput
                label="Freight Cost"
                wrapperClass="input-wrapper price"
                inputSymbol="$"
                value={initialValues.freight_cost}
                onChange={(value) => {
                  onInputChange('freight_cost', value);
                  setTotalValue((
                    Number(initialValues?.total_cost || 0)
                        + Number(value || 0)
                        + Number(initialValues?.prep_fee || 0)
                        + Number(initialValues?.packaging_fee || 0)
                  ));
                }}
                onBlur={() => onInputChange('freight_cost', initialValues.freight_cost ? Number(initialValues.freight_cost).toFixed(2) : '')}
                pattern="decinumeric"
                min={0}
                max={999999}
              />
              <CustomInput
                label="Packaging Fee"
                wrapperClass="input-wrapper price"
                inputSymbol="$"
                value={initialValues.packaging_fee}
                onChange={(value) => {
                  onInputChange('packaging_fee', value);
                  setTotalValue((
                    Number(initialValues?.total_cost || 0)
                        + Number(initialValues?.freight_cost || 0)
                        + Number(initialValues?.prep_fee || 0)
                        + Number(value || 0)
                  ));
                }}
                onBlur={() => onInputChange('packaging_fee', initialValues.packaging_fee ? Number(initialValues.packaging_fee).toFixed(2) : '')}
                pattern="decinumeric"
                min={0}
                max={999999}
              />
            </div>
          </div>

          <div className="total-cost-fields">
            <div className="true-total-cost-field">
              <span className="total-cost-label">True Total Cost:</span>
              <span className="value">
                $
                {
                    Number((Number(initialValues?.freight_cost) || 0)
                    + (Number(initialValues?.prep_fee) || 0)
                    + (Number(initialValues?.packaging_fee) || 0)
                    + (Number(initialValues?.total_cost) || 0)).toFixed(2)
                }
              </span>
            </div>
            <div className="total-cost-field">
              <span className="total-cost-label">Total Admin Cost:</span>
              <span className="value">
                $
                {
                    Number((Number(initialValues?.unit_cost) || 0)
                    + (Number(initialValues?.freight_cost) || 0)
                    + (Number(initialValues?.prep_fee) || 0)
                    + (Number(initialValues?.packaging_fee) || 0)).toFixed(2)
                }
              </span>
            </div>
          </div>
        </div>
        )}
      </div>
    </DetailsSectionContainer>
  );
}

export default Details;

const DetailsSectionContainer = styled.div`
  display: flex;
  justify-content: center;

  > div {
    max-width: 400px;
  }

  .dimensions-section {
    display: grid;
    align-items: center;
    background-color: #fef4e2;
    border-radius: 8px;
    padding: 8px;
    margin: 8px;
    margin-right: 0;
    grid-template-columns: 25% 21% 13% 17% 16%;
    column-gap: 3px;
    font-weight: normal;
    margin-top: 16px;
    margin-left: 0;

    .th-row {
      margin-right: 10px;

      &.feet {
        max-width: 67px;
        margin-left: -7px;
      }
    }

    .input-wrapper {
      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      &.in {
        max-width: 67px;
        margin-left: -7px;
      }
    }

    .row, .or {
      font-size: 12px;
      @media screen and (min-width: 768px) {
        font-size: 13px;
      }
    }

    .or {
      color: ${(props) => props.theme.primaryColor};
      margin-top: 4px;
    }

    .row {
      color: ${(props) => props.theme.primaryTextColor};
      text-align: end;
      margin-right: 13px;
      margin-top: 1px;
      font-size: 13px;
    }

    .col {
      margin-left: -15px;
      color: ${(props) => props.theme.primaryTextColor};
      margin-bottom: -6px;
      font-size: 8px;

      &.feet {
        margin-left: -31px;
      }

      &.in {
        margin-left: -31px;
      }

      @media screen and (min-width: 768px) {
        font-size: 10px;
      }
      text-transform: uppercase;
    }

    .input-wrapper {
      margin-top: 8px;
      padding: 0;
    }

    input {
      width: 100%;
      border: none;
    }
  }

  .bg {
    background-color: #fef4e2;
    border-radius: 8px;
    padding: 8px;
    margin: 8px;
    margin-right: 0;
    align-items: center;
    margin-left: 0;

    .input-wrapper {
      padding: 0;
      max-width: 500px;

      > div {
        width: 100%;
        max-width: 300px;
        flex: 4;
      }

      input {
        width: 100%;
      }

      label {
        text-align: end;
        flex: 2;
        white-space: nowrap;
      }
    }

    input {
      width: 198px;
      border: none;
    }
  }

  .small-inputs-container {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 10px;
    min-width: 187px;
    @media screen and (max-width: 768px) {
      display: block;
      gap: 10px;
      padding-top: 0;
      padding-bottom: 0;
      .select-wrapper {
        margin-top: 10px;
      }
    }
    gap: 22px;

    .input-wrapper {
      padding: 0;
      grid-template-columns: 2fr 2fr;
      @media screen and (max-width: 768px) {
        input {
          width: 100%;
        }
      }
    }

    .select-wrapper {
      padding: 0;
      grid-template-columns: 2fr 2fr;
      height: 33px;

      label {
        font-weight: normal;
      }

      .select-wrap {
        height: 33px;

        input {
          height: 20px;
        }
      }
    }
  }

  .price {
    display: grid;
    align-items: center;
    grid-template-columns: 4fr 6fr !important;
    gap: 0;

    label {
      text-align: end;
      margin-right: 10px;
    }
  }

  .price input {
    width: 76px;
    height: 33px;
  }

  .notes-input {
    margin-top: 10px;

    label {
      margin-top: 8px;
      color: ${(props) => props.theme.primaryTextColor};

    }
  }

  .notes-input .text-area-wrapper {
    display: flex;
    gap: 10px;

    textarea {
      height: 100px;
      width: 100%;
      border-radius: 4px;
      padding: 8px;
      padding-left: 12px;
      padding-top: 6px;
      color: ${(props) => props.theme.primaryTextColor};
    }
  }

  .price-inputs {
    &.right {
      .input-wrapper {
        grid-template-columns: 6fr 4fr !important;
      }
    }
  }

  .admin-cost {
    @media screen and (max-width: 768px) {
      margin-top: 15px;
      grid-template-columns: 1fr;
      .small-inputs-container {
        grid-template-columns: 1fr 1fr;
      }
      .total-cost {
        margin-top: 10px;
      }
    }
    grid-column-end: span 2;
    margin-top: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: 1px 1px 5px 5px rgb(112 112 112 / 31%);

    .total-cost {
      display: grid;
      color: #232323;
      padding-bottom: 20px;
      grid-template-columns: 4fr 6fr !important;
      margin-left: 10px;
      align-items: center;
      .value {
        text-align: start;
      }
    }

    .admin-inputs-container {
      display: flex;

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    .admin-cost-section {
      .section-title {
        font-size: 20px;
      }
    }

    .total-cost-fields {
      padding-top: 20px;
      padding-left: 15px;
      text-align: start;

      .total-cost-label {
        font-weight: bold;
      }
    }
    
  }
`;
