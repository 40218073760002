import { useEffect, useMemo, useState } from 'react';
import {
  Route, Routes, Navigate, Link, useLocation, generatePath, useParams,
} from 'react-router-dom';
import { Loader } from 'rsuite';
import {
  getLocalStorage, getToken, intercomInitialization, roleHasAccess,
} from '../helpers';
import AdminLayout from '../Layouts/AdminLayout';
import AddUnit from '../pages/AddUnit';
import AdminLogin from '../pages/AdminLogin';
import AdminUserCreation from '../pages/AdminUserCreation';
import AdminUsers from '../pages/AdminUsers';
import BillingHistory from '../pages/Billing/BillingHistory';
import AddFund from '../pages/Billing/AddFund';
import DealerCreation from '../pages/DealerCreation';
import Dealers from '../pages/Dealers';
import ProviderCreation from '../pages/ProviderCreation';
import EditUnit from '../pages/EditUnit';
import Feeds from '../pages/Feeds';
import Location from '../pages/Location';
import Login from '../pages/Login';
import AuthLayout from '../Layouts/AuthLayout';
import RolesTable from '../pages/RolesTable';
import Scheduler from '../pages/SettingPages/Scheduler';
import SettingsCSV from '../pages/SettingPages/SettingsCSV';
import SettingsIntegrations from '../pages/SettingPages/SettingsIntegrations';
import SettingsOverlay from '../pages/SettingPages/SettingsOverlay';
import SettingsUsers from '../pages/SettingPages/SettingsUsers';
import SettingsLocation from '../pages/SettingPages/SettingsLocation';
import RolePage from '../pages/RolePage';
import Showrooms from '../pages/Showrooms';
import { getMeRequest } from '../store/auth/actions';
import {
  BillingRoles, SchedulerRoles, SettingRoles, SupperAdminRoles,
} from '../store/constants';
import Pages from './pages';
import InventoryManager from '../pages/InventoryManager';
import MainLayout from '../Layouts/MainLayout';
import Registration from '../pages/Registration';
import Providers from '../pages/Providers';
import ResetPassword from '../pages/ResetPassword';
import { useGetSelectedDealer } from '../hooks/getDealersHook';
import { LocalStorageKeys } from '../store/keys';
import { GetDealersById } from '../store/dealers/actions';

function PageNotFound() {
  return (
    <div>
      <h1>{'Page doesn\'t exist'}</h1>
      <div>
        <Link to={Pages.home}>
          Home
        </Link>
      </div>
    </div>
  );
}

type ProtectedRouteType = {
  isAllowed: boolean,
  redirectPath?: string,
  element: JSX.Element
}

function ProtectedRoute({ isAllowed, redirectPath = '/', element }: ProtectedRouteType) {
  const params = useParams();
  const pathParams = Object.keys(params).reduce((acc, currKey) => ({ ...acc, [currKey]: params[currKey] || '' }), {});
  const formedPath = generatePath(redirectPath, pathParams);

  if (!isAllowed) {
    return <Navigate to={formedPath} replace />;
  }
  return element;
}

function RoutesComponent() {
  const isLoggedIn = getToken();
  const location = useLocation();

  const userRequest = getMeRequest();
  const user = userRequest?.data?.data;
  const selectedDealer = useGetSelectedDealer();

  const idFromStorage = getLocalStorage(LocalStorageKeys.DEALER_ID);
  const getDealerRequest = GetDealersById(idFromStorage, !!(idFromStorage && roleHasAccess(userRequest?.data?.data?.role, SupperAdminRoles))); // get specific dealer is available only for "admin"
  const adminInitialization = idFromStorage && roleHasAccess(userRequest?.data?.data?.role, SupperAdminRoles) ? !!getDealerRequest?.data : true;
  useEffect(() => {
    if (userRequest?.data?.data && adminInitialization) {
      intercomInitialization(userRequest?.data?.data, getDealerRequest?.data);
    }
  }, [userRequest]);

  const routes: RouteType[] = [
    {
      path: Pages.inventoryManager,
      element: <InventoryManager />,
      systemPermissions: [],
      redirect: Pages.inventoryManager,
    },
    {
      path: Pages.editUnit,
      element: <EditUnit />,
      systemPermissions: [],
      redirect: Pages.editUnit,
    },
    {
      path: Pages.addUnit,
      element: <AddUnit />,
      systemPermissions: [],
      redirect: Pages.addUnit,
    },
    {
      path: Pages.addUnitPure,
      element: <AddUnit />,
      systemPermissions: [],
      redirect: Pages.addUnitPure,
    },
    {
      path: Pages.billingAddFunds,
      element: <AddFund />,
      systemPermissions: [roleHasAccess(user?.role, BillingRoles)],
      redirect: Pages.billingAddFunds,
    },
    {
      path: Pages.billingHistory,
      element: <BillingHistory />,
      systemPermissions: [roleHasAccess(user?.role, BillingRoles)],
      redirect: Pages.billingHistory,
    },
  ];

  const routesMapper = (route: RouteType) => (
    <Route
      element={(
        <ProtectedRoute
          isAllowed={route?.systemPermissions?.every(Boolean)}
          redirectPath={route?.redirect}
          element={route.element}
        />
      )}
      path={route.path}
      key={route.path}
    />
  );

  const mappedAuthRoutes = useMemo(() => (
    <Route element={<AuthLayout />}>
      {authRoutes.map((el) => routesMapper(el))}
    </Route>
  ), [authRoutes, location, isLoggedIn]);
  const mappedRoutes = useMemo(() => (
    <Route element={<MainLayout />}>
      {routes.map((el) => routesMapper({
        ...el,
        systemPermissions: [!!isLoggedIn, ...el.systemPermissions],
      }))}
    </Route>
  ), [routes, isLoggedIn, location]);

  const mappedAdminRoutes = useMemo(() => (
    <Route element={<MainLayout />}>
      {dealerRoutes.map((el) => routesMapper({
        ...el,
        systemPermissions: [roleHasAccess(user?.role, SettingRoles), !!isLoggedIn],
      }))}
    </Route>
  ), [routes, isLoggedIn, location, user?.role]);
  const mappedScheduleRoutes = useMemo(() => (
    <Route element={<MainLayout />}>
      {scheduleRoutes.map((el) => routesMapper({
        ...el,
        systemPermissions: [roleHasAccess(selectedDealer?.id ? selectedDealer?.role : user?.role, SchedulerRoles), !!isLoggedIn],
      }))}
    </Route>
  ), [routes, isLoggedIn, location, selectedDealer, user?.role]);
  const mappedSuperAdminRoutes = useMemo(() => (
    <Route element={<AdminLayout />}>
      {superAdminRoutes.map((el) => routesMapper({
        ...el,
        systemPermissions: [roleHasAccess(user?.role, SupperAdminRoles), !!isLoggedIn],
        redirect: Pages.inventoryManager,
      }))}
    </Route>
  ), [routes, isLoggedIn, location, user?.role]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!!isLoggedIn && (!selectedDealer && !user)) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoggedIn, selectedDealer, user]);

  if (isLoading) {
    return <Loader center />;
  }

  return (
    <Routes>
      {mappedAuthRoutes}
      {mappedRoutes}
      {mappedAdminRoutes}
      {mappedScheduleRoutes}
      {mappedSuperAdminRoutes}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default RoutesComponent;

type RouteType = {
  path: string,
  element: JSX.Element,
  systemPermissions: Array<boolean>
  redirect?: string
}

const scheduleRoutes: RouteType[] = [
  {
    path: Pages.SchedulerSetting,
    element: <Scheduler />,
    systemPermissions: [],
  },
  {
    path: Pages.SchedulerProfileFromSetting,
    element: <Scheduler />,
    systemPermissions: [],
  },
  {
    path: Pages.SchedulerFbProfileFromSetting,
    element: <Scheduler />,
    systemPermissions: [],
  },
  {
    path: Pages.SchedulerSettingTabs,
    element: <Scheduler />,
    systemPermissions: [],
  },
];
const dealerRoutes: RouteType[] = [
  {
    path: Pages.LocationSetting,
    element: <SettingsLocation />,
    systemPermissions: [],
  },
  {
    path: Pages.CSVSetting,
    element: <SettingsCSV />,
    systemPermissions: [],
  },
  {
    path: Pages.IntegrationsSetting,
    element: <SettingsIntegrations />,
    systemPermissions: [],
  },
  {
    path: Pages.OverlaySetting,
    element: <SettingsOverlay />,
    systemPermissions: [],
  },
  {
    path: Pages.UsersSetting,
    element: <SettingsUsers />,
    systemPermissions: [],
  },
  {
    path: Pages.location,
    element: <Location />,
    systemPermissions: [],
  },
  {
    path: Pages.editLocation,
    element: <Location />,
    systemPermissions: [],
  },
];

const authRoutes: RouteType[] = [
  {
    path: Pages.login,
    element: <Login />,
    systemPermissions: [],
  },
  {
    path: Pages.adminLogin,
    element: <AdminLogin />,
    systemPermissions: [],
  },
  {
    path: Pages.home,
    element: <Login />,
    systemPermissions: [],
  },
  {
    path: Pages.resetPassword,
    element: <ResetPassword />,
    systemPermissions: [],
  },
  {
    path: Pages.registration,
    element: <Registration />,
    systemPermissions: [],
  },
];

const superAdminRoutes: RouteType[] = [
  {
    path: Pages.dealerCreation,
    element: <DealerCreation />,
    systemPermissions: [],
  },
  {
    path: Pages.dealerEdit,
    element: <DealerCreation />,
    systemPermissions: [],
  },
  {
    path: Pages.dealersPage,
    element: <Dealers />,
    systemPermissions: [],
  },
  {
    path: Pages.providersPage,
    element: <Providers />,
    systemPermissions: [],
  },
  {
    path: Pages.providerCreation,
    element: <ProviderCreation />,
    systemPermissions: [],
  },
  {
    path: Pages.providerEdit,
    element: <ProviderCreation />,
    systemPermissions: [],
  },
  {
    path: Pages.adminUsersPage,
    element: <AdminUsers />,
    systemPermissions: [],
  },
  {
    path: Pages.adminUserCreation,
    element: <AdminUserCreation />,
    systemPermissions: [],
  },
  {
    path: Pages.adminUserEdit,
    element: <AdminUserCreation />,
    systemPermissions: [],
  },
  {
    path: Pages.feedsPage,
    element: <Feeds />,
    systemPermissions: [],
  },
  {
    path: Pages.showroomsPage,
    element: <Showrooms />,
    systemPermissions: [],
  },
  {
    path: Pages.rolesPage,
    element: <RolesTable />,
    systemPermissions: [],
  },
  {
    path: Pages.rolePage,
    element: <RolePage />,
    systemPermissions: [],
  },
];
