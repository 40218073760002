import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useAppMessageContext } from '../../appMessagesContext';
import CustomButton from '../../components/Buttons/CustomButton';
import CustomTable from '../../components/CustomTable';
import DeleteModal from '../../components/DeleteModal';
import CustomCheckbox from '../../components/inputs/CustomCheckbox';
import BinSvg from '../../components/SvgElements/BinSvg';
import useNavigateHook from '../../hooks/useNavigateHook';
import Pages from '../../Router/pages';
import {
  AdminUserType, DeleteAdminUserRequest,
  GetAdminUsers,
} from '../../store/dealers/actions';
import squareEdit from '../../assets/square-with-pencil.png';

export interface AdminUsersTableData extends AdminUserType {
  actions: string
}

type UsersTableColumns = {
  label: string
  key: keyof Pick<AdminUserType, 'first_name' | 'created_at'> | 'action' | 'id' | 'last_name' | 'department'
  width?: number
}

function AdminUsers() {
  const navigate = useNavigateHook();
  const { showMessage } = useAppMessageContext();
  const [showModal, setShowModal] = useState<{id: number} | boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const deleteAdminUserRequest = DeleteAdminUserRequest();

  const { data: adminUsers, refetch: refetchAdminUsers, isFetching } = GetAdminUsers();
  const usersIds = useMemo(() => adminUsers.map((user) => user.id), [adminUsers]);

  const handleCreateUser = () => {
    navigate(Pages.adminUserCreation);
  };

  const modalText = () => (
    <div>
      <h3>
        Permanently Delete
      </h3>
      <div className="text">
        Are you sure you would like
        <br />
        to Permanently Delete these
        <br />
        Users(s)?
      </div>
    </div>
  );
  useEffect(() => {
    if (deleteAdminUserRequest.isSuccess) {
      deleteAdminUserRequest.reset();
      refetchAdminUsers();
      showMessage({ type: 'success', message: 'User(s) Deleted successfully' });
    }
  }, [deleteAdminUserRequest.isSuccess]);

  const handleModalAccept = () => {
    if (typeof showModal === 'object') {
      deleteAdminUserRequest.mutate({ ids: [showModal?.id] });
    } else {
      deleteAdminUserRequest.mutate({ ids: selectedItems });
    }
    setShowModal(false);
  };

  const handleCheckAll = () => {
    if (selectedItems.length === usersIds.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(usersIds);
    }
  };

  return (
    <Container>
      <div className="head">
        <div className="title">
          Users
        </div>
        <div className="control">
          <div className="search">
            {/* <SearchInput */}
            {/*  value={search} */}
            {/*  onChange={(value) => setSearch(value)} */}
            {/* /> */}
          </div>
          <div className="buttons-container">
            <div className="remove-btn-container">
              {selectedItems.length
                ? (
                  <IconWrapper className="remove-ico" onClick={() => setShowModal(true)}>
                    <BinSvg />
                  </IconWrapper>
                ) : null}
            </div>
            <div className="create-button">
              <CustomButton type="submit" onClick={handleCreateUser}>Create User</CustomButton>
            </div>
          </div>
        </div>
      </div>
      <div className="table-wrap">
        <div className="table-header">
          <div className="header-checkbox">
            <CustomCheckbox
              onChange={() => handleCheckAll()}
              checked={(selectedItems.length === adminUsers.length) && !!adminUsers.length}
              indeterminate={!!(selectedItems.length && selectedItems.length !== adminUsers.length)}
            />
            <div className="arrow" />
          </div>
        </div>
        <CustomTable
          loading={isFetching}
          data={adminUsers.map((dataRow: AdminUsersTableData) => ({
            ...dataRow,
            item_id: <IdCell item={dataRow} />,
            created_at: <CreatedAt item={dataRow} />,
            action: <ActionsCell
              onEditClick={() => navigate(`${Pages.adminUserCreation}/${dataRow.id}`)}
              onRemoveClick={() => setShowModal({ id: dataRow.id })}
            />,
          }))}
          columns={columns}
          onItemSelect={(selected) => setSelectedItems(selected)}
          selectable
          hideSelectHeader
          displayTotal={false}
          fillHeight={false}
          autoHeight
          fullHeightContainer={false}
          displayPagination={false}
          checkedKeysProp={selectedItems}
        />
      </div>
      <DeleteModal
        openObj={showModal}
        onApproveClose={handleModalAccept}
        onClose={() => setShowModal(false)}
        mainContent={modalText()}
      />
    </Container>
  );
}

export default AdminUsers;

function IdCell({ item }: {item: AdminUsersTableData}) {
  return (
    <div className="id-cell">
      {item.id}
    </div>
  );
}
function CreatedAt({ item }: {item: AdminUsersTableData}) {
  const today = new Date(item.created_at);
  const formatedDate = today.toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', year: '2-digit' });
  return (
    <div>
      {formatedDate}
    </div>
  );
}
type ActionsCellProps = {
  onEditClick: () => void
  onRemoveClick: () => void
}
function ActionsCell({ onRemoveClick, onEditClick }: ActionsCellProps) {
  return (
    <StyledActionCell>
      <button type="button" onClick={onEditClick}>
        <img src={squareEdit} alt="edit" />
      </button>
      <button type="button" className="remove-bin" onClick={onRemoveClick}>
        <BinSvg fill="#747474" stroke="#747474" />
      </button>
    </StyledActionCell>
  );
}

const StyledActionCell = styled.div`
  display: flex;
  align-items: center;
  .dots {
    margin-top: 12px;
    > div {
      width: 24px;
    }
   }
  .remove-bin {
    height: 20px;
    margin-top: 5px;
  }
`;

const columns: UsersTableColumns[] = [
  {
    label: 'USER ID',
    key: 'id',
    width: 120,
  },
  {
    label: 'FIRST NAME',
    key: 'first_name',
  },
  {
    label: 'LAST NAME',
    key: 'last_name',
  },
  {
    label: 'DEPARTMENT',
    key: 'department',
  },
  {
    label: 'CREATED DATE',
    key: 'created_at',
  },
  {
    label: '',
    key: 'action',
    width: 120,
  },
];
const Container = styled.div`
  padding-top: 33px;
    padding-right: 39px;
  .head {
    .title {
      color: ${(props) => props.theme.secondaryTextColor};
      font-size: 20px;
      font-weight: bold;
    }
    .control {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      .buttons-container {
        display: flex;
        .remove-btn-container {
          margin-right: 20px;
        }
      }
    }
    .create-button {
      .primary {
        text-transform: unset;
        border-radius: 4px;
      }
    }
  }
   .table-wrap {
     margin-top: 24px;
     background-color: #fff;
     border-radius: 8px;
     box-shadow: 0 5px 5px rgba(0, 0, 0, 0.29);

     .table-header {
       padding: 8px;
       display: flex;
       justify-content: space-between; 
       padding-right: 20px;
       .arrow {
         background-image: url("data:image/svg+xml,%3Csvg width='24' fill='%23747474' height='24' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cg%3E%3Ctitle%3ELayer 1%3C/title%3E%3Cpath stroke='null' id='svg_1' d='m1.1752,9.02285l8.56872,13.25122c1.29027,1.99536 3.37455,1.99536 4.66483,0l8.56872,-13.25122c2.08428,-3.22327 0.59551,-8.74887 -2.34895,-8.74887l-17.13744,0c-2.94446,0 -4.40015,5.5256 -2.31587,8.74887z'/%3E%3C/g%3E%3C/svg%3E");
         height: 15px;
         width: 16px;
         background-size: 6px 5px;
         background-repeat: no-repeat;
         background-position: 3px 5px;
         margin-left: 2px;
       }
       .header-checkbox {
         display: flex;
         align-items: center;
         .rs-checkbox-checker {
           padding: 14px;
         }
       }
       .header-filters {
         display: flex;
         align-items: center;
         cursor: pointer;
         img {
           width: 24px;
           height: 24px;
         }
       }
     }
     .rs-table-row-header {
       * {
         background-color: #f6f6f6;
       }
       .rs-table-cell-content {
         color: ${(props) => props.theme.secondaryTextColor};
       }
     }
     #table-wrapper {
         margin: 0;
         color: ${(props) => props.theme.secondaryTextColor};
       .rs-table-cell {
         border-bottom: 1px solid #c1c1c1;
         }
       }
     .table-footer {
       padding: 8px;
       display: flex;
       justify-content: space-between;
       color: ${(props) => props.theme.secondaryTextColor};
       font-size: 14px;
       button {
         color: #c1c1c1;
         font-weight: bold;
       }
     }
     .id-cell {
       color: ${(props) => props.theme.primaryColor};
     }
   }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.primaryColor};
  transition: all ease 0.2s;
  :hover {
    border: 2px solid ${(props) => props.theme.primaryColor};
  }
  img {
    width: 36px;
    height: 36px;
  }
`;
