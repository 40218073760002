import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { formSelectOptions, removeMultipleSpaces } from '../../../../helpers';
import { useGetSelectedDealer } from '../../../../hooks/getDealersHook';
import { getMeRequest } from '../../../../store/auth/actions';
import { LocationsI } from '../../../../store/settings/requests';
import {
  CreateUnitReqError,
  DatalinkListUnit,
  DatalinkUnit,
  getAutopopulateitem,
  getAutopopulateList,
  getInventoryManagerFilters, getManufacturesList,
  ItemCategory,
  ItemCategoryResponse,
  ItemType, StockValidationErrors,
} from '../../../../store/unit/actions';
import { DealershipLocation, SpecificFormInputs, SpecificInputsKeys } from '../../types';
import { InputsArrType } from '../Attributes/data';
import { inputsByInventoryType, InventoryTypeInputT } from './data';
import CustomSelect, { SelectUnknownOption, SelectValue } from '../../../../components/inputs/CustomSelect';
import CustomInput from '../../../../components/inputs/CustomInput';
import CustomButton from '../../../../components/Buttons/CustomButton';

export type AutopopulateOption = DatalinkListUnit & {
  label: string
  value: string
}
interface SpecificsProps {
  initialValues: SpecificFormInputs
  onInputChange: (key: SpecificInputsKeys, value: string | SelectValue | ItemCategory) => void
  errors?: {[key in SpecificInputsKeys]?: string}
  touched?: {[key in SpecificInputsKeys]?: boolean}
  disableInventoryType?: boolean
  itemsType: ItemType[]
  itemTypesLoading: boolean
  itemCategories: ItemCategoryResponse[]
  locations: LocationsI[]
  itemCategoriesLoading: boolean
  requestErrors?: CreateUnitReqError
  formReset: () => void
  handleAutopopulate: (item: DatalinkUnit) => void
  handleStockValidation: (stock: string) => void
  stockValidationErrors?: StockValidationErrors['errors']
}
function Specifics({
  initialValues, onInputChange, errors, touched, disableInventoryType,
  itemsType,
  itemTypesLoading,
  itemCategories,
  itemCategoriesLoading,
  requestErrors,
  locations,
  formReset,
  handleAutopopulate,
  handleStockValidation,
  stockValidationErrors,
}: SpecificsProps) {
  const [buildingTitle, setBuildingTitle] = useState<string>(initialValues.title);
  const selectedDealer = useGetSelectedDealer();
  const getMe = getMeRequest();
  const changeTitle = (value: SelectValue | ItemCategory, controlInput: InventoryTypeInputT['key']) => {
    const yearString = controlInput === 'year' ? (value as SelectValue)?.value : (initialValues.year as SelectValue)?.value || '';
    const manufacturerString = controlInput === 'manufacturer' ? (value as SelectValue)?.value || '' : (initialValues.manufacturer as SelectValue)?.value || '';
    const modelNameString = controlInput === 'model_name' ? value : initialValues?.model_name || '';
    const itemCategoryString = controlInput === 'item_category' ? (value as ItemCategory)?.name : (initialValues.item_category as ItemCategory)?.name || '';
    const formedTitle = `${yearString} ${manufacturerString} ${modelNameString} ${itemCategoryString}`.trim();
    setBuildingTitle(removeMultipleSpaces(formedTitle));
    onInputChange('title', removeMultipleSpaces(formedTitle));
  };

  const specificInputs = useMemo(() => (initialValues.inventoryType?.name
    ? inputsByInventoryType[initialValues.inventoryType?.name]
    : []), [initialValues.inventoryType, errors]);

  const handleChange = (value: SelectValue | string | ItemCategory, controlInput: InventoryTypeInputT['key']) => {
    onInputChange(controlInput, value);
  };
  const { responseData: managerFilters, isFetching: filtersIsFetching } = getInventoryManagerFilters(
    { dealer_id: selectedDealer?.id || undefined },
    (getMe.isFetching),
  );
  const { responseData: manufacturerList, isFetching: manufacturesFetching } = getManufacturesList();
  const { responseData: autopopulateList } = getAutopopulateList({
    year: initialValues?.year?.value,
    ...(initialValues?.manufacturer?.value ? { unitManufacturer: initialValues?.manufacturer?.value as string } : { unitManufacturer: undefined }),
  });
  const { responseData: autopopulateItem } = getAutopopulateitem(initialValues?.autopopulate?.id);
  const categoryOptions = useMemo(() => {
    const foundedCategory = itemCategories.find((category) => category.name === initialValues?.inventoryType?.name)
      ?.item_categories || [];
    return [...foundedCategory];
  }, [initialValues.inventoryType, itemCategories]);
  const manufacturerOptions = useMemo(() => formSelectOptions(manufacturerList?.map((make) => make?.name) || []), [manufacturesFetching]);
  const brandOptions = useMemo(() => formSelectOptions(managerFilters?.brand || []), [initialValues.inventoryType, filtersIsFetching]);

  const remappedRequestErrors: CreateUnitReqError | undefined = requestErrors ? { // item keys on FE and BE are different
    ...requestErrors,
    'specifics.dealership_location': requestErrors?.['specifics.dealership_location_id'] || '',
    'specifics.item_category': requestErrors?.['specifics.item_category_id'] || '',
  } : undefined;

  const onGoCllick = () => {
    if (autopopulateItem) {
      const manufactureValue = formSelectOptions([autopopulateItem.unitManufacturer?.toUpperCase()])[0];
      if (manufacturerOptions.findIndex((option) => option.value === autopopulateItem.unitManufacturer) < 0) {
        manufacturerOptions.push(manufactureValue); // mutate original object
      }
      handleChange(manufactureValue, 'manufacturer');

      handleAutopopulate(autopopulateItem);
      setBuildingTitle(removeMultipleSpaces(autopopulateItem.title));
    }
  };
  const renderInput = (input: InputsArrType) => {
    const optionLabel = (data: SelectUnknownOption) => {
      const value: string = data?.name ? String(data?.name) : String(data.label);
      Object.assign(data, { label: value });
      return (
        <div>{data.label}</div>
      );
    };
    const getSelectOptions = (select: InputsArrType) => {
      if (select.type === 'select') {
        switch (select.key) {
          case 'dealership_location':
            return locations.map((location) => {
              const formLocationOption: DealershipLocation = {
                id: location.id,
                name: location.name,
                is_main: location.is_main,
              };
              return formLocationOption;
            });
          case 'item_category':
            return categoryOptions;
          case 'brand':
            return brandOptions;
          case 'manufacturer':
            return manufacturerOptions;
          default:
            return select?.options;
        }
      }
      return [];
    };

    switch (input.type) {
      case 'building-title':
        return (
          <CustomInput
            label={input.label}
            wrapperClass={`input-wrapper building-title ${initialValues.inventoryType?.name.replace(' ', '-')}`}
            required={input.required}
            value={buildingTitle}
            onChange={(value) => {
              setBuildingTitle(removeMultipleSpaces(value));
              onInputChange('title', removeMultipleSpaces(value));
            }}
            error={errors?.[input.key] && touched?.[input.key] ? errors?.[input.key] : remappedRequestErrors?.[(`specifics.${input.key}`) as keyof CreateUnitReqError]?.[0]}
          />
        );
      case 'select':
        // eslint-disable-next-line no-case-declarations
        let typedText = '';
        // eslint-disable-next-line no-case-declarations
        const selectKeysWithCreatableOption: InventoryTypeInputT['key'][] = ['model_name'];
        return (
          <CustomSelect
            label={input.label}
            className="select-input"
            required={input.required}
            options={getSelectOptions(input)}
            value={initialValues[input.key] as SelectValue}
            isLoading={input.key === 'item_category' ? itemCategoriesLoading : false}
            {...(input.key === 'item_category' && { isOptionSelected: (data: ItemType) => data.id === initialValues.item_category?.id })}
            {...(input.key === 'dealership_location' && { isOptionSelected: (data: ItemType) => data.id === initialValues.dealership_location?.id })}
            formatOptionLabel={(data) => optionLabel(data)}
            isClearable={input.key === 'manufacturer' || input.key === 'year'}
            onChange={(value: SelectValue | ItemCategory) => {
              if (input.key !== 'status' && input.key !== 'condition' && input.key !== 'dealership_location') {
                changeTitle(value, input.key);
              }

              handleChange(value, input.key);
            }}
            onInputChange={(searchVal) => {
              typedText = searchVal;
            }}
            onBlur={() => {
              if (typedText && (selectKeysWithCreatableOption.includes(input.key))) {
                changeTitle(formSelectOptions([typedText])[0], input.key);
                handleChange(formSelectOptions([typedText])[0], input.key);
              }
            }}
            error={errors?.[input.key] && touched?.[input.key] ? errors?.[input.key] : remappedRequestErrors?.[(`specifics.${input.key}`) as keyof CreateUnitReqError]?.[0]}
          />
        );
      case 'select-with-button':
        // eslint-disable-next-line no-case-declarations
        const noItem = () => <div>{initialValues?.year?.value ? 'No Data' : 'Please select Year first'}</div>;
        return (
          <div className={`input-with-button ${initialValues.inventoryType?.name.replace(' ', '-')}`}>
            <CustomSelect
              label={input.label}
              className="select-input"
              required={input.required}
              options={autopopulateList?.map((item) => ({
                ...item,
                value: item.title,
                label: item.title,
              }))}
              noOptionsMessage={noItem}
              value={initialValues[input.key] as AutopopulateOption}
              onChange={(value: AutopopulateOption) => handleChange(value, input.key)}
              error={errors?.[input.key] && touched?.[input.key] ? errors?.[input.key] : remappedRequestErrors?.[(`specifics.${input.key}`) as keyof CreateUnitReqError]?.[0]}
            />
            <CustomButton
              variant="primary"
              className="primary-btn"
              wrapperClassname="primary-btn-wrapper"
              disabled={!autopopulateItem}
              onClick={onGoCllick}
            >
              Go
            </CustomButton>
          </div>
        );
      default:
        return (
          <CustomInput
            label={input.label}
            wrapperClass="input-wrapper"
            required={input.required}
            value={initialValues[input.key] as string}
            onChange={(value: string) => handleChange(value, input.key)}
            error={
              // eslint-disable-next-line no-nested-ternary
                input?.key === 'stock'
                  ? stockValidationErrors?.stock || remappedRequestErrors?.[(`specifics.${input.key}`) as keyof CreateUnitReqError]?.[0] || (errors?.[input.key] && touched?.[input.key] ? errors?.[input.key] : remappedRequestErrors?.[(`specifics.${input.key}`) as keyof CreateUnitReqError]?.[0])
                  : errors?.[input.key] && touched?.[input.key] ? errors?.[input.key] : remappedRequestErrors?.[(`specifics.${input.key}`) as keyof CreateUnitReqError]?.[0]
}
            pattern={input.pattern}
            {...(input.key === 'stock' && {
              onBlur: (e) => {
                handleStockValidation(e?.target?.value);
              },
            })}
          />
        );
    }
  };

  const inventoryTypeLabel = (data: ItemType) => (
    <div>{data.name}</div>
  );

  return (
    <SpecificsSectionContainer>
      <div className="select-input-container">
        <CustomSelect
          label="Inventory Type"
          className="select-input"
          required
          value={initialValues.inventoryType}
          isLoading={itemTypesLoading}
          onChange={(val) => {
            setBuildingTitle('');
            formReset(); // reset form because of inputs set changes
            onInputChange('inventoryType', val as SelectValue);
          }}
          options={itemsType}
          isOptionSelected={(data: ItemType) => data.id === initialValues.inventoryType?.id}
          formatOptionLabel={(data: ItemType) => inventoryTypeLabel(data)}
          error={errors?.inventoryType && touched?.inventoryType ? errors?.inventoryType : remappedRequestErrors?.[('specifics.item_type_id') as keyof CreateUnitReqError]?.[0]}
        />
        {specificInputs.map((input: InputsArrType) => (
          <React.Fragment key={input.label}>
            {renderInput(input)}
          </React.Fragment>
        ))}
      </div>
    </SpecificsSectionContainer>
  );
}

export default Specifics;

const SpecificsSectionContainer = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
  .date-select {
    margin-top: 10px;
    label {
      font-size: 13px;
      font-weight: normal;
      color: ${(props) => props.theme.primaryTextColor};
    }
    display: grid;
    margin-left: 5px;
    width: 100%;
    min-width: 230px;
    @media screen and (max-width: 1024px) {
      column-gap: 16px;
    }
    label {
      text-align: end;
    }
    input {
      width: 100%;
    }
    .input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      label {
        margin-right: 10px;
      }
    }
  }

  input {
    font-weight: normal;
    text-align: initial;
    padding-left: 20px;
  }
  .building-title {
    &.Building {
      margin-left: 0;
    }
    &.Equipment {
      margin-left: -6px;
    }
    &.Horse-Trailer {
      margin-left: -16px;
    } 
    &.Recreational-Vehicles {
      margin-left: -70px;
    } 
    &.Semi-Trailer {
      margin-left: -14px;
    } 
    &.Semi-Truck {
      margin-left: -11px;
    } 
    &.Sports-Vehicle {
      margin-left: -27px;
    } 
    &.Trailer-or {
      margin-left: -57px;
    }
    @media screen and (max-width: 768px) {
      margin-left: 0!important;
    }
  }
  .select-input-container {
    @media screen and (max-width: 768px) {
      max-width: 500px;
      width: 100%;
    }
  }
`;
