import SettingsLayout from '../../../components/SettingsLayout';
import Pages from '../../../Router/pages';
import OverlaySection from '../../SettingsSections/OverlaySection';

function SettingsOverlay() {
  return (
    <SettingsLayout navigateOnBack={Pages.inventoryManager} headerTitle="settings">
      <OverlaySection />
    </SettingsLayout>

  );
}
export default SettingsOverlay;
