import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../config/axios';
import { RolesType } from '../auth/types';
import {
  AutopostingData,
  CheckoutRequestData,
  CreateCLProfileRequestData, CreateCLScheduleRequestData, CreateFbProfileRequestData,
  CreateUserRequestI, CSVSaveDuplicatesRequestData, CSVUploadRequestData, OverlayResponse, SchedulesDeleteReqT,
  SetLocationRequest,
  UpdateOverlayRequestData,
  UpdateUserRequestI,
} from './actions';
import { UploadedFile } from '../../components/FileUploader';
import { AutoPostingConfigurationT } from '../../pages/SettingsSections/SchedulerSection/FbProfilesTab/FbProfileTabForm';

export interface LocationsI {
  id: number
  dealer_id: number
  is_main: boolean
  name: string
  contact_first_name: string
  contact_last_name: string
  website: string | null
  phone_number: string
  email: string[]
  street_address: string
  city: string
  state: string
  country: string
  zip_code: string
  county: string
  created_at: string
  updated_at: string
}

export interface UsersI {
  id: number
  email: string
  dealer_id: number
  created_at: string
  updated_at: string
  is_dealer_admin: boolean
  role: RolesType
}
export interface FbProfileI {
  id: number
  name: string
  fb_token: string
  fb_login: string
  fb_password: string
  dealer_id: number
  locations: []
  is_default: boolean
  autoposting_configuration: AutoPostingConfigurationT
}
export interface CustomStatusOverlayI {
  id?: number
  status_overlay: string | number | undefined
  status_overlay_url: string | undefined | null
  status_overlay_placement: string | number | undefined
  status_overlay_size: string | number | undefined
  status_overlay_file?: UploadedFile | null
  remove_status_overlay?: boolean | number
  dealer_id?: number
}
export interface ProfileI {
    id: number
    type: string
    name: string
    location_city: string
    location_district: string
    contact_name: string
    phone_number: string
    address: LocationsI | null
    category: string
    condition: string
    description: string
    dealer_id: number
    is_default: boolean
}
export const SettingRequests = {
  async GetLocations(params?: {available?: number, fb_profile_id?: number, dealer_id?: string | number}): Promise<AxiosResponse<LocationsI[]>> {
    return axiosInstance.get('/locations', { params });
  },
  async UpdateLocation(data: SetLocationRequest, id: string) {
    return axiosInstance.put(`/locations/${id}`, data);
  },
  async UpdateMainLocation(id: string) {
    return axiosInstance.post(`/locations/${id}/main`);
  },
  async DeleteLocation(id: string) {
    return axiosInstance.delete(`/locations/${id}`);
  },
  async DeleteLocations(ids: number[]) {
    return axiosInstance.delete('/locations/bulk', { data: { ids } });
  },
  async CreateLocation(data: SetLocationRequest) {
    return axiosInstance.post('/locations', data);
  },
  async GetUsers(params: {dealer_id?: string | number}): Promise<AxiosResponse<UsersI[]>> {
    return axiosInstance.get('/users', { params });
  },
  async CreateUser(data: CreateUserRequestI) {
    return axiosInstance.post('/users', data);
  },
  async UpdateUser(data: UpdateUserRequestI, id: number) {
    return axiosInstance.put(`/users/${id}`, data);
  },
  async DeleteUser(id: number) {
    return axiosInstance.delete(`/users/${id}`);
  },
  async BulkDeleteUser(ids: number[]) {
    return axiosInstance.delete('/users/bulk', { data: { ids } });
  },
  async GetOverlay(id: number): Promise<AxiosResponse<OverlayResponse>> {
    return axiosInstance.get(`/overlays/${id}`);
  },
  async UpdateOverlay(data: UpdateOverlayRequestData, id: number) {
    return axiosInstance.post(`/overlays/${id}`, {
      ...data,
      _method: 'PUT',
    }, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      },
    });
  },
  async UploadCSV(data: CSVUploadRequestData) {
    return axiosInstance.post('/csvFiles', {
      ...data,
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
  async CSVSaveDuplicates(data: CSVSaveDuplicatesRequestData, id: string | number) {
    return axiosInstance.post(`csvFiles/${id}/storeItemByStock`, data);
  },
  async GetCSVList(params: {dealer_id?: string | number}) {
    return axiosInstance.get('/csvFiles', { params });
  },
  async GetCustomStatusOverlays(params: {dealer_id?: string | number}) {
    return axiosInstance.get('/customStatusOverlays', { params });
  },
  async DeleteCustomStatusOverlay(id: number | string) {
    return axiosInstance.delete(`/customStatusOverlays/${id}`);
  },
  async GetFbProfiles(params: {dealer_id?: string | number}) {
    return axiosInstance.get('/dealer/schedule/fbProfiles', { params });
  },
  async GetFbProfileById(id: number | string, params?: {dealer_id?: string | number}) {
    return axiosInstance.get(`/dealer/schedule/fbProfiles/${id}`, { params });
  },
  async EditFbProfile(id: number | string, data: CreateFbProfileRequestData) {
    return axiosInstance.put(`/dealer/schedule/fbProfiles/${id}`, data);
  },
  async CreateFbProfile(data: CreateFbProfileRequestData) {
    return axiosInstance.post('/dealer/schedule/fbProfiles', data);
  },
  async DeleteFbProfile(id: number) {
    return axiosInstance.delete(`/dealer/schedule/fbProfiles/${id}`);
  },
  async GetProfiles(params: {dealer_id?: string | number}) {
    return axiosInstance.get('/dealer/schedule/dealerScheduleProfiles', { params });
  },
  async GetProfileById(id: number | string, params?: {dealer_id?: string | number}) {
    return axiosInstance.get(`/dealer/schedule/dealerScheduleProfiles/${id}`, { params });
  },
  async GetProfileOptions() {
    return axiosInstance.get('/dealer/schedule/profile/data/craigslist');
  },
  async CreateCLProfile(data: CreateCLProfileRequestData) {
    return axiosInstance.post('/dealer/schedule/dealerScheduleProfiles', data);
  },
  async EditCLProfile(id: number | string, data: CreateCLProfileRequestData) {
    return axiosInstance.put(`/dealer/schedule/dealerScheduleProfiles/${id}`, data);
  },
  async DeleteCLProfile(id: number) {
    return axiosInstance.delete(`/dealer/schedule/dealerScheduleProfiles/${id}`);
  },
  async GetTimezones() {
    return axiosInstance.get('/timezonesUS');
  },
  async CreateCLSchedule(data: CreateCLScheduleRequestData) {
    return axiosInstance.post('/dealer/dealerSchedules', data);
  },
  async GetCLSchedules(params: {dealer_id?: string | number}) {
    return axiosInstance.get('/dealer/dealerSchedules', { params });
  },
  async GetPaymentMethod(params: {dealer_id?: string | number}) {
    return axiosInstance.get('/billing/getPaymentMethod', { params });
  },
  async setPaymentMethod(params?: {dealer_id?: string | number}) {
    return axiosInstance.post('/billing/setPaymentMethod', { ...params });
  },
  async removePaymentMethod(params?: {dealer_id?: string | number}) {
    return axiosInstance.post('/billing/removePaymentMethod', { ...params });
  },
  async checkout(data: CheckoutRequestData) {
    return axiosInstance.post('/billing/checkout', data);
  },
  async GetBalance(params?: {dealer_id?: string | number}) {
    return axiosInstance.get('/billing/getBalance', { params });
  },
  async DeleteLSchedule(data: SchedulesDeleteReqT) {
    return axiosInstance.delete('/dealer/dealerSchedules', { data });
  },
  async GetBillingTransactions(params?: {dealer_id?: string | number}) {
    return axiosInstance.get('/billing/getTransactions', { params });
  },
  async ToggleAutoposting(data: AutopostingData) {
    return axiosInstance.post(`/dealers/${data.dealer_id}/toggleAutoposting`, data);
  },
};
